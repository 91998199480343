import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
import { HashLink } from '../components/link';
import googleComic from '../images/working_for_google.png';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Book reflection: `}<em parentName="h1">{`Competing with Unicorns`}</em></h1>
    <blockquote>
      <p parentName="blockquote">{`How the World’s Best Companies Ship Software and Work Differently`}</p>
    </blockquote>
    <p><em parentName="p">{`Competing with Unicorns`}</em>{` by Jonathan Rasmusson was a relatively short read compared to many software engineering books. Brevity seemed appropriate given the content and I appreciated the authors ability to get to the point.`}</p>
    <h2><HashLink id="takeaways" to="/competing-with-unicorns#takeaways" mdxType="HashLink">{`Non-unicorn company vs unicorn company takeaways`}</HashLink></h2>
    <p>{`There are a few big non-unicorn company vs unicorn company takeaways that I got out of reading `}<em parentName="p">{`Competing with Unicorns`}</em>{`.`}</p>
    <img alt="Working for google comic" title="https://xkcd.com/192/" src={googleComic} width="100%" />
    <h3><HashLink id="development" to="/competing-with-unicorns#development" mdxType="HashLink">{`Enterprise development vs startup development`}</HashLink></h3>
    <p>{`Jonathan stresses the importance of the distinction between the traditional enterprise development process and the development process of successful startups. There are a few case studies which allow us to deduce some general similarities amongst successful tech giants. `}</p>
    <p>{`Traditional enterprises are prone to value up-front planning over iteration which tends to be opposite for startup companies. Also, startups value discovery and learning over estimation which is not the case for most large enterprise companies. `}</p>
    <p>{`Now the reasoning behind these company values makes sense to me. Established non-unicorn companies tend to spend a lot of time developing for an internal audience by automating domestic processes to better support their customers whereas startups might not even know who their customers are yet!`}</p>
    <h3><HashLink id="missions" to="/competing-with-unicorns#missions" mdxType="HashLink">{`Projects vs missions`}</HashLink></h3>
    <p>{`The idea is that team members perform better when working for a higher purpose rather than checking somebody elses boxes. The author posits that a small autonomous team that's on an ongoing `}<strong parentName="p">{`mission`}</strong>{` tends to produce better results than a constrained team on a finite `}<strong parentName="p">{`project`}</strong>{`. `}</p>
    <p>{`While I agree with his hypothesis generally, I'm not sure all of the author's assumptions fit the bill for every project team. There `}<em parentName="p">{`can`}</em>{` be project teams in traditional enterprise companies with the autonomy to get the job done correctly and efficiently without being micromanaged and obligated to original requirements. That being said, I understand the purpose of the polarity here in order to make the point. `}</p>
    <p>{`I also agree that people tend to cut less corners when they will maintain the system they build. Throwing a system over the wall to another team after meeting a deadline doesn't give engineers much incentive to sacrifice precious time to make that system as robust as possible.`}</p>
    <h3><HashLink id="hierarchy" to="/competing-with-unicorns#hierarchy" mdxType="HashLink">{`Corporate hierarchy vs flat reporting structure`}</HashLink></h3>
    <p>{`The value of a relatively flat reporting structure is something that I've witnessed first hand. At least for me, there's tremendous benefit in working `}<strong parentName="p">{`with`}</strong>{` your company rather than `}<strong parentName="p">{`for`}</strong>{` your company. `}</p>
    <p>{`In the book, Jonathan highlights team organization where he worked at Spotify. Small autonomous squads work together in chapters, tribes and guilds to make an impact on the company`}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup>{`. These squads should have as little dependence on others as possible and are usually comprised of engineers, a product manager and a data scientist or two. `}</p>
    <p>{`The importance of data scientists is one of my biggest takeaways from this book. It definitely makes sense to have a person dedicated to gathering, cleaning and analyzing sensitive company data in order to drive key success metrics and facilitate efficient scalability.`}</p>
    <h2><HashLink id="main-takeaway" to="/competing-with-unicorns#main-takeaway" mdxType="HashLink">{`Main takeaway: unicorns continuously improve`}</HashLink></h2>
    <p>{`Unicorns continuously deliver using techniques such as feature flags and release trains. The feedback loop between the builders and the users is as tight as possible. They instrument their product to track user behavior in order to gain insight into the product usability. They use this and other data to determine results of A/B tests which are basically permutations of feature flags for subsets of the user base. These unicorns are also analyzing data with data scientists and machine learning. `}</p>
    <p>{`This continuous improvement is what sets unicorns apart from the competition.`}</p>
    <p><em parentName="p">{`Competing with Unicorns`}</em>{` is not the best book if you're looking to learn about transitioning from a traditional enterprise to operating like a startup. However, this is a great book to learn how some successful startups are organized and how they operate.`}</p>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}><a parentName="li" {...{
            "href": "https://www.youtube.com/watch?v=4GK1NDTWbkY"
          }}>{`Spotify Engineering Culture (by Henrik Kniberg)`}</a><a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      